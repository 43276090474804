import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/common.service';

interface iContactForm {
  firstname: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  tnc: boolean,
  message: string,
  smsOptIn: boolean,
  emailOptIn: boolean,

}
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {
  contactForm: FormGroup;
  isFormValid : boolean  = true;
  constructor(private service: CommonService, private formBuilder: FormBuilder, @Inject(PLATFORM_ID) private platformId: any){
    this.service.scrollToTop()
    this.contactForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: [''],
      tnc: ['', Validators.required],
      message: [''],
      smsOptIn: [true],
      emailOptIn: [true],
    });
  }

  numberOnly(event: any) {
    return this.service.numberOnly(event);
  }

  submitForm() {
    console.log(this.contactForm.value);
    if (this.contactForm.valid && this.contactForm.value.tnc == true) {
      this.service.showSpinner()
      console.log(this.contactForm.value);
      this.service.postApi('contactuses/createContact', this.contactForm.value).subscribe((res: any) => {
        if (res.status == 200) {
          
          this.service.hideSpinner();
          this.service.showSuccess(res.message);
          this.resetFormFunction()
        }
      })
    } else {
      this.isFormValid = false
      this.service.hideSpinner();
      // this.service.showError('Something went wrong')
    }
  }


  resetFormFunction() {
    this.isFormValid = true
    this.contactForm.reset({
      firstname: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      tnc: false,
      message: '',
      smsOptIn: true,
      emailOptIn: true,
    })
  }

}
