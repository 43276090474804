import { Component , Renderer2, Inject} from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MetaService } from './meta.service';
import { Meta} from '@angular/platform-browser';
import { CommonService } from './common.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public showHeader: Boolean = true;
  private BASE_URL: string = 'https://www.kargone.com/';
  public title: string = 'Test Meta tag'
  public desctiption: string = `List and sell your used car in 3 simple steps & start receiving offers for your car from verified local dealers. No charges or hidden fees—it is absolutely free!`
  private SCHEMA_SCRIPT_ID: string = 'homePageSchema';
  public schema: string = '';
  constructor(
    private router: Router, 
    private activatedRoute: ActivatedRoute, 
    private metaService: MetaService, 
    private meta: Meta,
    private renderer: Renderer2,
    private service: CommonService,
    @Inject(DOCUMENT) private document: Document,
  ) {



    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (event) {
        // this.metaService.updateTitle(this.title);

        const currentRoute = event.urlAfterRedirects; // Gets the current route's path
        
        const routeSegments = currentRoute.split('/');
        const lastRouteSegment = routeSegments[routeSegments.length - 1];
        if (lastRouteSegment == '') {
          this.metaService.updateTitle(`Sell Car Used To Local Car Dealers | Kargoneeee`);
          this.metaService.updateMetaTag('og:title', 'Sell Car Used To Local Car Dealers | Kargone');
          this.metaService.updateMetaTag('og:description', 'Sell Car Used To Local Car Dealers With Kargone. 100% Free. No Charges or Hidden Fees. Visit Kargone To List & Sell Car Used Within 24 Hours');
          this.metaService.updateMetaTag('description', 'Sell Car Used To Local Car Dealers With Kargone. 100% Free. No Charges or Hidden Fees. Visit Kargone To List & Sell Car Used Within 24 Hours');
        }
        else if (lastRouteSegment == 'about') {
          console.log("inside about meta tag");
       
          this.removeSchema()
          this.metaService.updateMetaTag('og:title', 'About Kargone | Sell Car Used To Local Car Dealers');
          this.metaService.updateMetaTag('og:description', 'Kargone is designed to facilitate selling used cars in the the easiest and most profitable way possible. Access unlimited offers for your used car at zero cost.');
          this.metaService.updateMetaTag('description', 'Kargone is designed to facilitate selling used cars in the the easiest and most profitable way possible. Access unlimited offers for your used car at zero cost.');
        
          this.metaService.updateMetaTag('twitter:title', 'About Kargone | Sell Car Used To Local Car Dealers');
           this.metaService.updateMetaTag('twitter:description', 'Kargone is designed to facilitate selling used cars in the the easiest and most profitable way possible. Access unlimited offers for your used car at zero cost.');
        

        }
        else if (lastRouteSegment == 'how-we-work') {
          this.removeSchema()
          this.metaService.updateTitle(`How kargone Works | Sell Car Used To Local Car Dealers`);
          this.metaService.updateMetaTag('description', `Your vehicle can be listed in under 2 minutes. Enter a VIN or license plate, answer a few questions about the condition of the vehicle, and upload some photos..`);
          this.metaService.updateMetaTag('og:title', 'How kargone Works | Sell Car Used To Local Car Dealers');
          this.metaService.updateMetaTag('og:description', 'Your vehicle can be listed in under 2 minutes. Enter a VIN or license plate, answer a few questions about the condition of the vehicle, and upload some photos.');
          this.metaService.updateMetaTag('twitter:title', 'How kargone Works | Sell Car Used To Local Car Dealers');
          this.metaService.updateMetaTag('twitter:description', 'Your vehicle can be listed in under 2 minutes. Enter a VIN or license plate, answer a few questions about the condition of the vehicle, and upload some photos.'); 
        }
        else if (lastRouteSegment == 'privacy-policy') {
          this.removeSchema()
          this.metaService.updateTitle(`Privacy Policy | Sell Car Used To Local Car Dealers | Kargone`);
          this.metaService.updateMetaTag('description', `For any inquiries regarding these Terms or any other matters related to Company, you can reach us at info@kargone.com.`);
          this.metaService.updateMetaTag('og:title', 'Privacy Policy | Sell Car Used To Local Car Dealers | Kargone');
          this.metaService.updateMetaTag('og:description', `For any inquiries regarding these Terms or any other matters related to Company, you can reach us at info@kargone.com.`);
          this.metaService.updateMetaTag('twitter:title', 'Privacy Policy | Sell Car Used To Local Car Dealers | Kargone');
          this.metaService.updateMetaTag('twitter:description', `For any inquiries regarding these Terms or any other matters related to Company, you can reach us at info@kargone.com.`);
        }
        else if (lastRouteSegment == 'term-condition') {
          this.removeSchema()
          this.metaService.updateTitle(`Terms & Conditions | Sell Car Used To Local Car Dealers | Kargone`);
          this.metaService.updateMetaTag('description', `Welcome to Kargone, a web-based service that connects private automobile sellers with automobile dealerships, streamlining the process of vehicle sales.`);

          this.metaService.updateMetaTag('og:title', 'Terms & Conditions | Sell Car Used To Local Car Dealers | Kargone');
          this.metaService.updateMetaTag('og:description', 'Welcome to Kargone, a web-based service that connects private automobile sellers with automobile dealerships, streamlining the process of vehicle sales.');
          this.metaService.updateMetaTag('twitter:title', 'Terms & Conditions | Sell Car Used To Local Car Dealers | Kargone');
          this.metaService.updateMetaTag('twitter:description', 'Welcome to Kargone, a web-based service that connects private automobile sellers with automobile dealerships, streamlining the process of vehicle sales.');
        }
        else if (lastRouteSegment == 'faq') {
          this.removeSchema()
          this.metaService.updateTitle(`Frequently Asked Questions | Sell Car Used To Local Car Dealers | Kargone`);
          this.metaService.updateMetaTag('description', `Find answers to the frequently asked questions by used car sellers about Kargone.`);
          this.metaService.updateMetaTag('og:title', 'Frequently Asked Questions | Sell Car Used To Local Car Dealers | Kargone');
          this.metaService.updateMetaTag('og:description', 'Find answers to the frequently asked questions by used car sellers about Kargone.');
          this.metaService.updateMetaTag('twitter:title', 'Frequently Asked Questions | Sell Car Used To Local Car Dealers | Kargone');
           this.metaService.updateMetaTag('twitter:description', 'Find answers to the frequently asked questions by used car sellers about Kargone.');
        }
        else if (lastRouteSegment == 'contact-us') {
          this.removeSchema()
          this.metaService.updateTitle(`Contact Us | Sell Car Used To Local Car Dealers | Kargone`);
          this.metaService.updateMetaTag('description', `For any inquiries regarding vehicle listing, please email info@kargone.com.`);
          this.metaService.updateMetaTag('og:title', 'Contact Us | Sell Car Used To Local Car Dealers | Kargone');
          this.metaService.updateMetaTag('og:description', 'For any inquiries regarding vehicle listing, please email info@kargone.com.');
          this.metaService.updateMetaTag('twitter:title', 'Contact Us | Sell Car Used To Local Car Dealers | Kargone');
           this.metaService.updateMetaTag('twitter:description', 'For any inquiries regarding vehicle listing, please email info@kargone.com.');
        }
        else {
      
          this.metaService.updateTitle(`Kargone`);
          this.metaService.updateMetaTag('description', `Your vehicle can be listed in under 2 minutes. Enter a VIN or license plate, answer a few questions about the condition of the vehicle, and upload some photos..`);
          this.metaService.updateMetaTag('og:title', 'How kargone Works | Sell Car Used To Local Car Dealers');
          this.metaService.updateMetaTag('og:description', 'Your vehicle can be listed in under 2 minutes. Enter a VIN or license plate, answer a few questions about the condition of the vehicle, and upload some photos.');
          this.metaService.updateMetaTag('twitter:title', 'How kargone Works | Sell Car Used To Local Car Dealers');
          this.metaService.updateMetaTag('twitter:description', 'Your vehicle can be listed in under 2 minutes. Enter a VIN or license plate, answer a few questions about the condition of the vehicle, and upload some photos.'); 
        }


        // common upadte url 
        // this.metaService.updateMetaTag('twitter:url', `${this.BASE_URL}${lastRouteSegment}`);
        this.metaService.updateMetaTag('og:url', this.BASE_URL+lastRouteSegment);

        const verificationRouteSegment = routeSegments[routeSegments.length - 2];
        const resetPassword = routeSegments[1];
        if (lastRouteSegment == 'signup' || verificationRouteSegment == 'verification' || resetPassword == 'reset-password') {
          this.showHeader = false;
        }
        else {
          this.showHeader = true
        }
      }
    });
  }


  
  // ngOnInit(): void {
  //   this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
  //     if (event) {
  //       // this.metaService.updateTitle(this.title);

  //       const currentRoute = event.urlAfterRedirects; // Gets the current route's path
        
  //       const routeSegments = currentRoute.split('/');
  //       const lastRouteSegment = routeSegments[routeSegments.length - 1];
  //       console.log("lastRouteSegment",lastRouteSegment);
        
  //       if (lastRouteSegment == '') {
      
  //         let script = this.renderer.createElement('script');
  //         script.type = `application/ld+json`;
  //         script.text = `
  //             {
  //                 "@context": "https://schema.org",
  //                 "@type": "ContactPage",
  //                 "mainEntityOfPage": {
  //                   "@type": "WebPage",
  //                   "@id": "https://kargone.com/contact-us"
  //                 },
  //                 "publisher": {
  //                   "@type": "Organization",
  //                   "@id": "https://kargone.com",
  //                   "name": "Kargone",
  //                   "logo": "https://kargone.com/assets/icons/logo.svg", // REPLACE WITH ORIGINAL LOGO URL
  //                   "telephone": "+18888161418",
  //                   "email": "info@kargone.com",
  //                   "sameAs": [
  //                     "https://www.youtube.com/@KargoneOfficial",
  //                     "https://www.tiktok.com/@itskargone",
  //                     "https://www.instagram.com/kargoneofficial"
  //                   ],
  //                   "url": "https://kargone.com/",
  //                   "description": "Sell your car online to verified dealers within 24 hours for free on Kargone.",
  //                   "address": {
  //                     "@type": "PostalAddress",
  //                     "streetAddress": "16310 Batchellors Forest Rd",
  //                     "addressLocality": "Olney",
  //                     "addressRegion": "MD",
  //                     "addressCountry": "US",
  //                     "postalCode": "20832"
  //                   }
  //             }
  //         `;

  //       this.renderer.appendChild(this.document.body, script);

  //       }else if(lastRouteSegment == 'about'){
  //         let script = this.renderer.createElement('script');
  //         script.type = `application/ld+json`;
  //         script.text = `
  //             {
  //                 "@context": "https://schema.org",
  //                 "@type": "AboutPage",
  //                 "mainEntityOfPage": {
  //                   "@type": "WebPage",
  //                   "@id": "https://kargone.com/contact-us"
  //                 },
  //                 "publisher": {
  //                   "@type": "Organization",
  //                   "@id": "https://kargone.com",
  //                   "name": "Kargone",
  //                   "logo": "https://kargone.com/assets/icons/logo.svg", // REPLACE WITH ORIGINAL LOGO URL
  //                   "telephone": "+18888161418",
  //                   "email": "info@kargone.com",
  //                   "sameAs": [
  //                     "https://www.youtube.com/@KargoneOfficial",
  //                     "https://www.tiktok.com/@itskargone",
  //                     "https://www.instagram.com/kargoneofficial"
  //                   ],
  //                   "url": "https://kargone.com/",
  //                   "description": "Sell your car online to verified dealers within 24 hours for free on Kargone.",
  //                   "address": {
  //                     "@type": "PostalAddress",
  //                     "streetAddress": "16310 Batchellors Forest Rd",
  //                     "addressLocality": "Olney",
  //                     "addressRegion": "MD",
  //                     "addressCountry": "US",
  //                     "postalCode": "20832"
  //                   }
  //             }
  //         `;

  //         console.log("this.document",this.document);
          
  //         this.renderer.appendChild(this.document.body, script);
  //       }
  //     }
  //   })

  // }

  private getTitle(route: ActivatedRoute): string {
    // Implement logic to get the title based on the route
    // For example, you can use route.snapshot.data to store title information in the route data
    return 'Test Title';
  }

  private getDescription(route: ActivatedRoute): string {
    // Implement logic to get the description based on the route
    // For example, you can use route.snapshot.data to store description information in the route data
    return 'Test1 Description';
  }

  updateMetaTag(){
    this.meta.updateTag({ property: 'og:description', content: 'vishal' });
  }

  private removeSchema() {
    if(this.service.isBrowserPlateformId()){
    const script = document.getElementById(this.SCHEMA_SCRIPT_ID);
    if (script) {
      this.renderer.removeChild(document.head, script);
    }
    }
  }



  
}

