import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CarSubmissionComponent } from "./car-submission/car-submission.component";
import { PersonalDetailsComponent } from "../components/personal-details/personal-details.component";
import { RatingComponent } from "./rating/rating.component";
import { ChangePasswordComponent } from "../components/change-password/change-password.component";
import { NotificationComponent } from "./notification/notification.component";
import { DataGridComponent } from "../components/data-grid/data-grid.component";
import { DealerListComponent } from "./dealer-list/dealer-list.component";
import { AuthGuard } from "../auth/auth.guard";
import { ViewcarInfoComponent } from "../dealeraccount/viewcar-info/viewcar-info.component";
import { addCarGuardGuard } from "../auth/add-car-guard.guard";
//canActivate:[AuthGuard]
const routes :Routes = [
    {
    path:'',
    children:[
        {path:'',component:CarSubmissionComponent,pathMatch:'full'},
        {path:'add-car',component:CarSubmissionComponent, canActivate:[addCarGuardGuard]},
        {path:'edit-car/:id',component:CarSubmissionComponent,canActivate:[AuthGuard] },
        {path:'seller-profile',component:PersonalDetailsComponent,canActivate:[AuthGuard] },
        {path: 'rating',component: RatingComponent,canActivate:[AuthGuard] },
        {path: 'change-password',component: ChangePasswordComponent},
        {path: 'sms-email',component: NotificationComponent,canActivate:[AuthGuard] },
        {path: 'car-list',component: DataGridComponent,canActivate:[AuthGuard] },
        {path: 'offer/:id',component: DealerListComponent ,canActivate:[AuthGuard] },
        {path: 'rating/:id/:carVin',component: RatingComponent,canActivate:[AuthGuard]  },
        { path: 'view-car-info/:id',component: ViewcarInfoComponent ,canActivate:[AuthGuard] },

    ]
}
]
@NgModule({
    imports:[RouterModule.forChild(routes)],
    exports:[RouterModule]
})
export class MyaccountRoutingModule {}