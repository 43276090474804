import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs';
import { AuthService } from './auth.service';
import { CommonService } from '../common.service';

export const AuthGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {

  const currentUrl = state.url;
 
  const authService: AuthService = inject(AuthService);
  const service: CommonService = inject(CommonService)
  const router: Router = inject(Router);
  const route: ActivatedRoute = inject(ActivatedRoute);

  return authService.isAuthenticated().pipe(
    map((status:any) => {
      if (status.status == true) {
        return true;
      }
      if(service.isBrowserPlateformId()){
        localStorage.clear();
        sessionStorage.setItem('lastUrlValue', currentUrl)
      }
      service.user.next({})
      return router.createUrlTree(['/login']);
    })
  );
};
