<ngx-spinner size="large"  bdColor="rgba(51,51,51,0.8)" color="#fff"
type="cog"  ><i class="fa-solid fa-car-side text-white text-4xl" ></i>
</ngx-spinner>
<!-- <ngx-spinner
  type="custom" size="small" widt bdColor="rgba(51,51,51,0.4)"
>
  <img style="width: 35%;margin: auto;" src="assets/Kargone_hero_blink_black background_small.gif" alt="Loading..." />
</ngx-spinner> -->
<app-header *ngIf="showHeader"></app-header>
<app-comfirm-modal></app-comfirm-modal>
<router-outlet></router-outlet>
<app-footer></app-footer>
