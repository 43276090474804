import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/common.service';
declare var $:any

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {
  public star = [1,2,3,4,5]
  public rating : number = -1;
  public dealerId:number = 0;
  public user:any;
  public userData:any;
  public ratingForm: FormGroup;
  public ratingData:any;
  public dealerList:any;
  public carVin:number = 0;


  constructor(private fb: FormBuilder, private service: CommonService, private router: Router, private activateRaoute: ActivatedRoute, private renderer: Renderer2) {
    // this.getCalendar();
    if(this.service.isBrowserPlateformId()){
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.user = JSON.parse(`${localStorage.getItem('user')}`)
    }
    this.ratingForm = this.fb.group({
      dealershipName: ['', Validators.required],
      employeeName: ['', Validators.required],
      comment: [''],
    });
    this.activateRaoute.params.subscribe((res: any) => {
      this.dealerId = res.id;
      this.carVin = res.carVin;
      console.log("car id", this.carVin)
      if(!this.dealerId){
        this.dealerId =  this.user.id
      }
      let data={
        userId:this.dealerId,
        parentUserId: this.user.role == 0 ? this.user.parentUserId : 0
      }
      this.getDealerRating(data);
      this.getDealerDeatails();
      this.getAllSubDealer();
    })
    
  }

  ngOnInit(): void {
    if(this.service.isBrowserPlateformId()){
   
      // Dynamically load CSS
      const link = this.renderer.createElement('link');
      link.rel = 'stylesheet';
      link.href = 'https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.css';
      this.renderer.appendChild(document.head, link);
  
      // Dynamically load JS
      const script = this.renderer.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js';
      script.onload = () => {
        console.log('Swiper script loaded');
      };
      this.renderer.appendChild(document.body, script);
    }
  }
  updateRating(i:number){
    this.rating = i;
  }

  getDealerRating(data:any){
    this.service.showSpinner();
    this.service.postApi(`ratings/get-ratings`,data).subscribe((res: any) => {
      if (res.status == 200) {
        this.ratingData = res.ratings;
        this.getCalendar();
        this.service.hideSpinner();
      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner();
    })
  }

  getDealerDeatails() {
    this.service.showSpinner();
    let data = {
      userId:this.dealerId,
      parentUserId :0
    }
    this.service.postApi(`users/dealer-details`,data).subscribe((res: any) => {
    // this.service.getApi(`users/get-user/${this.dealerId}`).subscribe((res: any) => {
      if(res.status ==200){
        this.userData = res.user;
        this.ratingForm.patchValue({
          dealershipName : res.user.dealershipName,
          employeeName: res.user.username
        })
        this.service.hideSpinner();
      }
      else{
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner()
    })
  }
  getAllSubDealer(){
    this.service.showSpinner();
    let data = {
      filterPage : true,
      userId : this.user.id
    }
    this.service.postApi(`users/list`, data).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.dealerList = res.users;
          this.service.hideSpinner();
        }
        else {
          this.service.hideSpinner();
        }
      },
      error => {
        console.error('Error fetching cars:', error);
        this.service.hideSpinner();
      }
    );
  }

 
  getCalendar(){
    $(() => {
      $('input[name="daterange"]').daterangepicker({
        opens: 'center',
        // singleDatePicker: true,
        
        locale: {
          format: 'DD MMM YYYY'
        }
      }, (start:any, end:any, label:any) => {
        let data = {
          startDate: start.format('YYYY-MM-DD'),
          endDate: end.format('YYYY-MM-DD'),
          userId: this.user.id,
          parentUserId : this.user.parentUserId ? this.user.parentUserId : 0
        }
        this.filterByDate(data)
      });
    });
   
  }

  filterByDate(data:any){
    this.getDealerRating(data);
  }

  filterByEmployee(event:any){
    let data = {
      // startDate: start.format('YYYY-MM-DD'),
      // endDate: end.format('YYYY-MM-DD'),
      empId: event.target.value,
      userId: this.user.id,
      parentUserId : this.user.parentUserId ? this.user.parentUserId : 0
    }
    this.getDealerRating(data);

  }

  filterByStar(event:any){
    let data = {
      // startDate: start.format('YYYY-MM-DD'),
      // endDate: end.format('YYYY-MM-DD'),
      star: event.target.value,
      userId: this.user.id,
      parentUserId : this.user.parentUserId ? this.user.parentUserId : 0
    }
    this.getDealerRating(data);
  }

  onSubmit(){
    let data={
      sellerId: this.user.id,
      dealerId : this.dealerId,
      comment : this.ratingForm.value.comment,
      dealershipName:this.ratingForm.value.dealershipName,
      employeeName:this.ratingForm.value.employeeName,
      star: this.rating+1,
      carVin: this.carVin
    }
    
    this.service.postApi(`/ratings/create-rating`,data).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.showSuccess(res.message);
        let data={
          userId:this.dealerId,
          parentUserId: this.user.role == 0 ? this.user.parentUserId : 0,
      
        }
        this.getDealerRating(data);
        this.service.hideSpinner();
      }
      else {
        this.service.hideSpinner();
        this.service.showError(res.message);
      }
    }, (error) => {
      this.service.hideSpinner();
      this.service.showError('Something went wrong');
    })
  }
}
